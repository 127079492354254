import React from 'react';
import coffee from '../../assets/img/coffee.png';


const image = () => (
    <div>
        <img src={coffee} alt="Intern" style={{width: '200px'}}/>
    </div>
);

export default image;