import React from 'react';
import student from '../../assets/img/graduation.png';


const image = () => (
    <div>
        <img src={student} alt="Graduating Student" style={{width: '200px'}}/>
    </div>
);

export default image;