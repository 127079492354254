import React from 'react';
import thor from '../../assets/img/Matt.PNG';


const image = () => (
    <div>
        <img src={thor} alt="Matt" style={{width: '200px', borderRadius: '50%'}}/>
    </div>
);

export default image;