import React from 'react';
import thor from '../../assets/img/Kat.PNG';


const image = () => (
    <div>
        <img src={thor} alt="Kat" style={{width: '200px', borderRadius: '50%'}}/>
    </div>
);

export default image;