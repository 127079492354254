import React from 'react';
import computer from '../../assets/img/computer.png';


const image = () => (
    <div>
        <img src={computer} alt="Front End Developer" style={{width: '200px'}}/>
    </div>
);

export default image;