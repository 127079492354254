import React from 'react';
import scientist from '../../assets/img/beer.png';


const image = () => (
    <div>
        <img src={scientist} alt="Scientist" style={{width: '200px'}}/>
    </div>
);

export default image;